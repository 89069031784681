import { useShallow } from "zustand/react/shallow";

import { AppState, useAppStore } from "../store";

// https://stackoverflow.com/a/78216267/434
// Caution: useShallow ignores nested updates. To handle deep changes:
// 1. Modify root-level properties
// 2. Implement deep equality in store/selector
// 3. Use standard useAppStore for affected states
export function useAppState<T extends AppState, K extends keyof AppState>(...items: K[]): Pick<T, K> {
  const obj = useAppStore(useShallow((state) => items.reduce((acc, item) => ({ ...acc, [item]: state[item] }), {})));
  return obj as Pick<T, K>;
}
