import { Box, Button, Grid, Heading, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import Meta from "~/layout/Meta";

const Page404 = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => navigate("/");

  const testError = () => {
    throw new Error("Test error");
  };

  return (
    <Grid gap={4} textAlign="center" p="10">
      <Meta title="404" />
      <Heading onDoubleClick={() => testError()}>Page not Found</Heading>

      <Box maxWidth={[280, 400]} marginX="auto">
        <Image width={400} src="/assets/404 Error-rafiki.svg" />
      </Box>

      <Box>
        <Button onClick={handleBackToHome}>Go to your dashboard</Button>
      </Box>
    </Grid>
  );
};

export default Page404;
