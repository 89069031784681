import { Flex } from "@chakra-ui/react";
import { UserProfile } from "@clerk/clerk-react";

import Meta from "~/layout/Meta";

const AccountPage = () => {
  return (
    <>
      <Meta title="Account" />
      <Flex align={["top", "top"]}>
        <UserProfile />
      </Flex>
    </>
  );
};

export default AccountPage;
