import type { PathRouteProps } from "react-router-dom";

import HomePage from "../pages/HomePage";

import AccountPage from "~/pages/AccountPage";
import AdminPage from "~/pages/AdminPage";
import FailedPage from "~/pages/FailedPage";
import SyncHistoryPage from "~/pages/SyncHistoryPage";

interface Props extends PathRouteProps {
  bodyPadding?: number[];
  bgColor?: string;
}

export const routes: Array<Props> = [];

export const privateRoutes: Array<Props> = [
  {
    path: "/",
    element: <HomePage />
  },
  {
    path: "/failed",
    element: <FailedPage />
  },
  {
    path: "/account",
    element: <AccountPage />
  },
  {
    path: "/sync-history",
    element: <SyncHistoryPage />
  },
  {
    path: "/admin",
    element: <AdminPage />
  }
];
