import { ReactNode } from "react";

import { Icon } from "@chakra-ui/icons";
import { IoIosCart, IoIosAlbums } from "react-icons/io";
import { MdError } from "react-icons/md";

export enum UserRole {
  User = "User",
  Admin = "Admin"
}

// const allRoles = [UserRole.User, UserRole.Admin];

export interface MenuLink {
  label: string;
  path: string;
  target?: string;
  icon?: ReactNode;
  userRoles: UserRole[];
}

export const TopMenuLinks: MenuLink[] = [
  // { label: "Team", path: "https://www/our-team", target: "_blank", userRoles: allRoles }
];

export const SideMenuLinks: MenuLink[] = [
  { label: "Completed", path: "/", icon: <Icon as={IoIosCart} />, userRoles: [UserRole.User] },
  { label: "Failed", path: "/failed", icon: <Icon as={MdError} />, userRoles: [UserRole.User] },
  { label: "Sync History", path: "/sync-history", icon: <Icon as={IoIosAlbums} />, userRoles: [UserRole.User] }
];
