import { Flex } from "@chakra-ui/react";
import { OrganizationProfile } from "@clerk/clerk-react";

import Meta from "~/layout/Meta";

const AdminPage = () => {
  return (
    <>
      <Meta title="Admin" />
      <Flex align={["top", "top"]}>
        {/* <Link href="https://large-krill-24.accounts.dev/organization/organization-members" target="_blank">
        Manage Admin Members
      </Link> */}
        <OrganizationProfile />
      </Flex>
    </>
  );
};

export default AdminPage;
