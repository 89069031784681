import { useEffect, useState } from "react";

import { RepeatIcon } from "@chakra-ui/icons";
import { Box, Heading, HStack, IconButton, Input, Spinner, VStack } from "@chakra-ui/react";
import { Order } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import OrderCard from "~/components/OrderCard";
import Meta from "~/layout/Meta";

const HomePage = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredResults, setFilteredResults] = useState<Order[]>([]);

  const fetchTestData = async () => {
    const response = await fetch("/api/orders/all");
    const data = (await response.json()) as Order[];
    return data;
  };

  const recentOrders = useQuery({
    queryKey: ["completed-orders"],
    queryFn: fetchTestData
  });

  useEffect(() => {
    if (!recentOrders.data) return;
    setFilteredResults(recentOrders.data);
  }, [recentOrders.data]);

  useEffect(() => {
    if (!searchText) {
      setFilteredResults(recentOrders.data || []);
    } else {
      const filteredResults = recentOrders.data?.filter((x) => {
        return JSON.stringify(x).toLocaleLowerCase().includes(searchText.toLowerCase());
      });
      setFilteredResults(filteredResults || []);
    }
  }, [searchText]);

  if (recentOrders.isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" pt={10}>
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Meta title="Completed" description="Welcome to NFC Admin" />
      <HStack mb="4">
        <Heading as="h2">Completed Orders</Heading>
        <IconButton aria-label="Refresh Orders" icon={<RepeatIcon />} onClick={() => recentOrders.refetch()} />
      </HStack>
      <Input
        value={searchText}
        placeholder="Search orders..."
        mb={4}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      {filteredResults.length === 0 && <Box>No results</Box>}
      <VStack w="full" alignItems="stretch" overflow={"hidden"}>
        {filteredResults?.map((order) => <OrderCard key={order.id} order={order} />)}
      </VStack>
    </>
  );
};

export default HomePage;
