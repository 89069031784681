import React from "react";

import { Box, VStack, Text, HStack, Flex, Button, Badge } from "@chakra-ui/react";
import { FailedOrder } from "@shared/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { formatShortDateTime } from "~/helpers/date.helper";

interface Props {
  order: FailedOrder;
  showDetails: (order: FailedOrder) => void;
}

const FailedOrderCard: React.FC<Props> = ({ order, showDetails }) => {
  const queryClient = useQueryClient();

  const toggleReviewedMutation = useMutation({
    mutationFn: async (newValue: boolean) => {
      const response = await fetch("/api/orders/toggle-reviewed", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ id: order.id, reviewed: newValue })
      });
      if (!response.ok) {
        alert("Failed to toggle reviewed status");
      }
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["failed-orders"] });
    }
  });

  const buildNameValueLine = (name: string, value: string) => {
    return (
      <Text>
        <Text fontWeight={600} as="span">
          {name}:{" "}
        </Text>
        {value}
      </Text>
    );
  };

  const toggleReviewed = (newValue: boolean) => {
    toggleReviewedMutation.mutate(newValue);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
      <Flex flexDir={{ base: "column", lg: "row" }} gap={[0, 0, 6]}>
        <VStack align="start" spacing={1} flex={1}>
          <HStack>
            <Text fontWeight="bold">Order Number: {order.orderNumber}</Text>
          </HStack>

          {buildNameValueLine("Order ID", order.id)}
          {buildNameValueLine("Error", order.errorMsg)}
          {buildNameValueLine("Synced On", formatShortDateTime(new Date(order.syncedOn)))}
          <Button onClick={() => showDetails(order)}>Show Details</Button>
        </VStack>
        <VStack align="start" spacing={1} flex={1}>
          <Badge colorScheme={order.reviewed ? "green" : "red"}>{order.reviewed ? "Reviewed" : "Not Reviewed"}</Badge>
          <Button onClick={() => toggleReviewed(!order.reviewed)} isLoading={toggleReviewedMutation.isPending}>
            Mark as {order.reviewed ? "Unreviewed" : "Reviewed"}
          </Button>
        </VStack>
      </Flex>
    </Box>
  );
};

export default FailedOrderCard;
