import { Flex, Link, Text } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Flex
      width="full"
      align="center"
      alignSelf="flex-end"
      justifyContent="center"
      borderTop="1px solid"
      borderTopColor={"gray.200"}
      bg={"white"}
      p={"15px"}
    >
      <Text fontSize="xs">
        Copyright &copy; {new Date().getFullYear()} by{" "}
        <Link href="https://www.frenchteachers.org/concours" isExternal>
          AATF
        </Link>
      </Text>
    </Flex>
  );
};

export default Footer;
