import React from "react";

import { Box, VStack, Text, Badge, HStack, Flex } from "@chakra-ui/react";
import { Order } from "@shared/models";

import { formatShortDateTime } from "~/helpers/date.helper";

interface OrderCardProps {
  order: Order;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
  const buildNameValueLine = (name: string, value: string) => {
    return (
      <Text>
        <Text fontWeight={600} as="span">
          {name}:{" "}
        </Text>
        {value}
      </Text>
    );
  };

  if (!order) {
    return null;
  }

  console.log(order);

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} bg="white">
      <Flex flexDir={{ base: "column", lg: "row" }} gap={[0, 0, 6]}>
        <VStack align="start" spacing={1} flex={1}>
          <HStack>
            <Text fontWeight="bold">Order Number: {order.orderNumber}</Text>
            <Badge colorScheme={order?.status?.toLowerCase() === "paid" ? "green" : "orange"}>{order.status}</Badge>
          </HStack>

          {buildNameValueLine("Order ID", order.id)}
          {buildNameValueLine("Email", order.emailAddress)}
          {buildNameValueLine("Member Name", order.memberName)}
          {buildNameValueLine("Ordered On", formatShortDateTime(new Date(order.orderedOn)))}
          {order.syncedOn && buildNameValueLine("Synced On", formatShortDateTime(new Date(order.syncedOn)))}
        </VStack>
        <VStack align="start" spacing={1} flex={1}>
          {buildNameValueLine("Lingco Order ID", order.lingcoOrderId)}
          {buildNameValueLine("Chapter", order.chapterName)}
          <Text fontWeight={600}>Products:</Text>
          {order.products.map((product, index) => (
            <Box as="li" key={index} pl={4}>
              <Text as="span">
                {product.name} (Qty: {product.qty})
              </Text>
            </Box>
          ))}
        </VStack>
      </Flex>
    </Box>
  );
};

export default OrderCard;
