import { Flex } from "@chakra-ui/react";
import { SignUp, SignedIn, SignedOut } from "@clerk/clerk-react";
import { Navigate, useLocation } from "react-router-dom";

import { useLinkRouteIntercept } from "../hooks/useLinkRouteIntercept";

import Meta from "~/layout/Meta";

const SignUpPage = () => {
  const location = useLocation();
  useLinkRouteIntercept();

  const redirectTo = new URLSearchParams(location.search).get("redirectTo");
  const fullRedirectTo = redirectTo ? `?redirectTo=${redirectTo}` : "";

  return (
    <>
      <Meta title="Sign Up" />
      <Flex height={"100dvh"} justify={"center"} align={["top", "center"]}>
        <SignedOut>
          <SignUp routing="hash" signInUrl={`/signin${fullRedirectTo}`} forceRedirectUrl={redirectTo} />
        </SignedOut>
        <SignedIn>
          <Navigate to={redirectTo || "/"} />
        </SignedIn>
      </Flex>
    </>
  );
};

export default SignUpPage;
