import { useEffect, useState } from "react";

import { RepeatIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack
} from "@chakra-ui/react";
import { FailedOrder } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import FailedOrderCard from "~/components/FailedOrderCard";
import Meta from "~/layout/Meta";

const FailedPage = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredResults, setFilteredResults] = useState<FailedOrder[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<FailedOrder | null>(null);
  const [showOnlyUnreviewed, setShowOnlyUnreviewed] = useState(true);
  const fetchTestData = async () => {
    const response = await fetch("/api/orders/failed");
    const data = (await response.json()) as FailedOrder[];
    return data;
  };

  const recentOrders = useQuery({
    queryKey: ["failed-orders"],
    queryFn: fetchTestData
  });

  useEffect(() => {
    if (!searchText && !showOnlyUnreviewed) {
      setFilteredResults(recentOrders.data || []);
    } else {
      let filteredResults = recentOrders.data?.filter((x) => {
        const searchString = JSON.stringify({ ...x, details: undefined }).toLocaleLowerCase();
        return searchString.includes(searchText.toLowerCase());
      });

      if (showOnlyUnreviewed) {
        filteredResults = filteredResults?.filter((x) => !x.reviewed);
      }
      setFilteredResults(filteredResults || []);
    }
  }, [recentOrders.data, searchText, showOnlyUnreviewed]);

  if (recentOrders.isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" pt={10}>
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Meta title="Failed" description="Welcome to NFC Admin" />
      <HStack mb="4">
        <Heading as="h2">Failed Orders</Heading>
        <IconButton aria-label="Failed Orders" icon={<RepeatIcon />} onClick={() => recentOrders.refetch()} />
      </HStack>
      <HStack mb={4} alignItems="center">
        <Input
          value={searchText}
          placeholder="Search recent orders..."
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
        <Checkbox isChecked={showOnlyUnreviewed} onChange={(e) => setShowOnlyUnreviewed(e.target.checked)} w="260px">
          Only Unreviewed
        </Checkbox>
      </HStack>
      {filteredResults.length === 0 && <Box>No results</Box>}
      <VStack w="full" alignItems="stretch" overflow={"hidden"}>
        {filteredResults?.map((order) => (
          <FailedOrderCard key={order.id} order={order} showDetails={setSelectedOrder} />
        ))}
      </VStack>
      <Modal isOpen={!!selectedOrder} onClose={() => setSelectedOrder(null)} size="xl">
        <ModalOverlay />
        <ModalContent maxW="900px">
          <ModalHeader>Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="70vh" overflow="auto">
            <pre>{JSON.stringify(selectedOrder?.details, null, 2)}</pre>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setSelectedOrder(null)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FailedPage;
