import { ReactNode } from "react";

import { Box } from "@chakra-ui/react";

interface BodyProps {
  bodyPadding?: number[];
  bgColor?: string;
  children: ReactNode;
}

export default function Body({ children, bodyPadding, bgColor }: BodyProps) {
  return (
    <Box p={bodyPadding} pb={6} height="100%" w="100%" bgColor={[bgColor || "#F9F8F8", "#F9F8F8"]}>
      <Box p={0} h={"100%"} minH={"calc(100vh - 166px)"} borderRadius={"lg"} maxW={1200} mx="auto">
        {children}
      </Box>
    </Box>
  );
}
