import { Box, ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";

import { durationInMs } from "~/helpers/duration.helper";
import Routings from "~/router/Routings";
import { theme } from "~/styles/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: durationInMs({ minutes: 2 })
    }
  }
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Box className="id--app">
        <ChakraProvider theme={theme} resetCSS={true}>
          <Router>
            <Routings />
          </Router>
        </ChakraProvider>
      </Box>
    </QueryClientProvider>
  );
};

export default App;
