import { Helmet } from "react-helmet";

const APP_NAME = "NFC Admin";

interface MetaProps {
  title: string;
  description?: string;
}

const Meta = ({ title, description }: MetaProps) => {
  return (
    <Helmet>
      <title>
        {APP_NAME} {title ? `| ${title}` : ""}
      </title>
      {description && <meta name="description" content={description} />}

      <meta name="application-name" content={APP_NAME} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={APP_NAME} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#FFFFFF" />
    </Helmet>
  );
};

export default Meta;
