import { useState } from "react";

import { RepeatIcon } from "@chakra-ui/icons";
import { Box, Text, Heading, HStack, IconButton, Spinner, VStack, Checkbox, Badge } from "@chakra-ui/react";
import { SyncHistory } from "@shared/models";
import { useQuery } from "@tanstack/react-query";

import { formatShortDateTime } from "~/helpers/date.helper";
import Meta from "~/layout/Meta";

const SyncHistoryPage = () => {
  const [includeVerbose, setIncludeVerbose] = useState(false);
  const [hideOnlySkipped, setHideOnlySkipped] = useState(true);

  const fetchSyncHistoryData = async (v: boolean) => {
    console.log("includeVerbose: ", includeVerbose);
    const response = await fetch(`/api/orders/sync-history?verbose=${v}`);
    const data = (await response.json()) as SyncHistory[];
    return data;
  };

  const recentSyncHistory = useQuery({
    queryKey: ["sync-history", includeVerbose, hideOnlySkipped],
    queryFn: () => fetchSyncHistoryData(includeVerbose)
  });

  const lastSyncRun = recentSyncHistory.data?.[0];

  const filteredSyncHistory = recentSyncHistory.data?.filter((x) => {
    if (
      hideOnlySkipped &&
      x.numOrdersSkipped > 0 &&
      x.numOrdersSynced === 0 &&
      x.numOrdersErrored === 0 &&
      x.status === "success"
    ) {
      return false;
    }
    return true;
  });

  if (recentSyncHistory.isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" pt={10}>
        <Spinner />
      </Box>
    );
  }

  const buildNameValueLine = (name: string, value: string | number) => {
    return (
      <Text>
        <Text fontWeight={600} as="span">
          {name}:{" "}
        </Text>
        {value}
      </Text>
    );
  };

  return (
    <>
      <Meta title="Sync History" />

      <VStack gap="4" alignItems="flex-start" mb="4">
        <HStack>
          <Heading as="h1">Sync History (past 3 days)</Heading>
          <IconButton aria-label="Refresh Orders" icon={<RepeatIcon />} onClick={() => recentSyncHistory.refetch()} />
        </HStack>
        {lastSyncRun && (
          <Badge colorScheme={lastSyncRun.status === "success" ? "green" : "red"}>
            Last run: {formatShortDateTime(new Date(lastSyncRun.createdOn))}
          </Badge>
        )}
        <HStack>
          <Checkbox isChecked={hideOnlySkipped} onChange={(e) => setHideOnlySkipped(e.target.checked)}>
            Only show "Synced" or "Errored"
          </Checkbox>
          <Checkbox isChecked={includeVerbose} onChange={(e) => setIncludeVerbose(e.target.checked)}>
            Include verbose logs
          </Checkbox>
        </HStack>
      </VStack>

      <VStack gap="10" w={"100%"}>
        {filteredSyncHistory &&
          filteredSyncHistory.map((x) => (
            <VStack bg="white" p="4" align="start" gap="1" alignContent={"start"} borderRadius="lg" w={"100%"}>
              <HStack alignItems="flex-start" gap="10" mb="4">
                <VStack align="start" gap="0">
                  <HStack>
                    {buildNameValueLine("Sync Run ID", x.id.toString())}
                    <Badge colorScheme={x.status === "success" ? "green" : "red"}>{x.status}</Badge>
                  </HStack>
                  {buildNameValueLine("Sync Duration", x.durationSec)}
                  {buildNameValueLine("Synced On", formatShortDateTime(new Date(x.createdOn)))}
                  {buildNameValueLine("Num orders", x.numOrders)}
                </VStack>
                <VStack align="start" gap="0">
                  {buildNameValueLine("Synced", x.numOrdersSynced)}
                  {buildNameValueLine("Skipped", x.numOrdersSkipped)}
                  {buildNameValueLine("Errored", x.numOrdersErrored)}
                </VStack>
              </HStack>
              <Text fontWeight={600}>Logs:</Text>
              <Box whiteSpace="pre-wrap">{x.logs}</Box>
            </VStack>
          ))}
      </VStack>
    </>
  );
};

export default SyncHistoryPage;
